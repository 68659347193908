import "./App.css";
import { Routes, Route } from "react-router-dom";
import { Home } from "./Pages/Home/Home";
import { About } from "./Pages/About/About";
import { FAQ } from "./Pages/FAQ/FAQ";
import { Product } from "./Pages/Products/Product";
import { CSR } from "./Pages/CSR/CSR";
import { Contact } from "./Pages/Contact/Contact";
import { Appbar } from "./Components/Shared/Appbar/Appbar";
import { Footer } from "./Components/Shared/Footer/Footer";
import { Individualproduct } from "./Components/ProductComponents/Individualproduct";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchPage } from "./Pages/SearchPage/SearchPage";

function App() {
  return (
    <div className="App">
      <Appbar />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/products" element={<Product />} />
        <Route path="/products/:id" element={<Individualproduct />} />
        <Route path="/csr" element={<CSR />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/search-results" element={<SearchPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
