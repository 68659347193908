import AxiosInstance from "../api";

const useAPI = () => {
  const get = async (endPoint) => {
    try {
      const response = await AxiosInstance.get(endPoint);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const getCategoriesdataById = async (endPoint, id) => {
    try {
      const response = await AxiosInstance.get(`${endPoint}/${id}`);
      return {
        data: response.data.data,
        error: null,
        success: true,
        message: "Fetch data success",
      };
    } catch (error) {
      return {
        data: null,
        error: error ? error.response.data.message : "Fetch data failed",
        success: false,
        message: "Fetch data failed",
      };
    }
  };

  const post = async (endPoint, data) => {
    try {
      const response = await AxiosInstance.post(endPoint, data);
      return {
        data: response.data.data,
        status: true,
        message: "Data has been saved",
      };
    } catch (error) {
      return {
        status: true,
        message: "Data has been saved",
      };
    }
  };

  return {
    get,
    getCategoriesdataById,
    post,
  };
};

export default useAPI;
