import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Category } from "./Category";
import { Help } from "./Help";
import { NavigationLinks } from "./NavigationLinks";
import { Contact } from "./Contact";
import { Container } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import "./Footer.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { endpoint } from "../../../constants/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";

export const Footer = () => {
  //horizontal line style
  const horizontalLineStyle = {
    border: "1px solid #D7BD8A",
    width: "100%",
    margin: "10px 0",
  };

  const [email, setEmail] = React.useState("");

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { data: contactData } = useQuery({
    queryKey: "contactData",
    queryFn: getData,
  });

  const handleSubscribe = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_KALUARTS_BASE_URL + `/newsletters`,
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      toast.success("Subscribed Successfully");
    } catch (error) {
      toast.error("Unabled to Subscribe, Please try again later");
    }
    setEmail("");
  };

  return (
    <Box sx={{ padding: "40px" }} className="footer">
      {contactData?.map((item) => {
        return (
          <Container>
            <Grid container xs={12}>
              <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                <NavigationLinks />
              </Grid>
              <Grid item xs={6} sm={4} md={4} lg={3} xl={3}>
                <Help />
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={4}
                lg={3}
                xl={3}
                sx={{
                  marginTop: {
                    xs: "40px",
                    sm: "0",
                    md: "0",
                    lg: "0",
                    xl: "0",
                  },
                }}
              >
                <Category />
              </Grid>
              <Grid
                item
                xs={6}
                sm={4}
                md={4}
                lg={3}
                xl={3}
                sx={{
                  marginTop: {
                    xs: "40px",
                    sm: "40px",
                    md: "40px",
                    lg: "0",
                    xl: "0",
                  },
                }}
              >
                <Contact />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  color: "var(--white)",
                  fontWeight: "bold",
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "17px",
                    lg: "20px",
                    xl: "20px",
                  },
                  margin: "50px 0px 10px 0px",
                }}
              >
                Sign Up For Updates
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginBlock: "20px",
                }}
              >
                <TextField
                  id="email"
                  type="email"
                  label="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{
                    style: {
                      height: "16px",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "18px",
                        lg: "20px",
                        xl: "20px",
                      },
                    },
                  }}
                  sx={{
                    width: {
                      xs: "320px",
                      sm: "320px",
                      md: "350px",
                      lg: "400px",
                      xl: "400px",
                    },
                    borderRadius: "5px",
                    backgroundColor: "var(--white)",
                    border: "0",

                    " & .MuiOutlinedInput-root": {
                      "  &.Mui-focused fieldset": {
                        borderColor: "var(--orange)",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "0px solid white",
                      borderRadius: "0",
                      padding: "0",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid var(--orange)",
                      },
                    //border colour when focused
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid var(--orange)",
                      },
                    //floating label color when focused
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "var(--orange)",
                      fontWeight: "bold",
                    },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "var( --light-orange)",
                    borderRadius: "2px",
                    height: "3rem",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "16px",
                      xl: "16px",
                    },
                    color: "var(--light-black)",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "var(--dark-orange)",
                    },
                  }}
                  onClick={handleSubscribe}
                >
                  Subscribe
                </Button>
              </Box>
            </Grid>
            <div style={horizontalLineStyle}></div>
            <Grid container xs={12}>
              <Grid item xs={4}>
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--white)",
                    fontSize: {
                      xs: "7px",
                      sm: "10px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                    margin: "10px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  All Copy Rights Reserved 2023
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--white)",
                    fontSize: {
                      xs: "7px",
                      sm: "10px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                    margin: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Developed & Designed by :{""}
                  <a
                    href="https://onewindowintl.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Box
                      sx={{
                        width: {
                          xs: "15px",
                          sm: "18px",
                          md: "25px",
                          lg: "25px",
                          xl: "25px",
                        },
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "./assets/comp_logo.png"}
                        alt="..."
                        style={{
                          width: "100%",
                          height: "100%",
                          marginLeft: "5px",
                        }}
                      />
                    </Box>
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "10px",
                  }}
                >
                  <a
                    href={item.facebook}
                    target="_blank"
                    style={{ color: "var(--white)", margin: "5px" }}
                    rel="noreferrer"
                  >
                    <FacebookIcon
                      sx={{
                        fontSize: {
                          xs: "20px",
                          sm: "25px",
                          md: "25px",
                          lg: "30px",
                          xl: "30px",
                        },
                      }}
                      className="fb-icon"
                    />
                  </a>
                  {/* <NavLink to="/" style={{ color: "var(--white)", margin: "5px" }}>
                    <InstagramIcon fontSize="large" className="insta-icon" />
                  </NavLink> */}
                  <a
                    href={`mailto:${item.email}`}
                    style={{ color: "var(--white)", margin: "5px" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <EmailIcon
                      className="mail-icon"
                      sx={{
                        fontSize: {
                          xs: "20px",
                          sm: "25px",
                          md: "25px",
                          lg: "30px",
                          xl: "30px",
                        },
                      }}
                    />
                  </a>
                </Box>
              </Grid>
            </Grid>
          </Container>
        );
      })}
    </Box>
  );
};
