import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button, Typography, Container } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { NavLink } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";
import { endpoint } from "../../../constants/endpoint";

export const Categoryproducts = ({ id }) => {
  const [displayedItems, setDisplayedItems] = useState(8);
  const { getCategoriesdataById } = useAPI();

  const getData = async () => {
    const res = await getCategoriesdataById(endpoint.category_product, id);
    return res.data;
  };

  const { isDataLoading, data: categoryproductData } = useQuery(
    ["categoryproductData", id], // Include id in the queryKey
    getData
  );

  const maxLines = 2; // Maximum number of lines in the remaining description

  const handleShowMore = () => {
    setDisplayedItems(categoryproductData?.length);
  };
  const handleShowLess = () => {
    setDisplayedItems(8);
  };

  return (
    <Container>
      <br />
      <Box sx={{ marginBottom: "60px" }}>
        <Grid container spacing={3} sx={{ marginBottom: "40px" }}>
          {isDataLoading ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "20px",
                }}
              >
                {[1, 2, 3, 4].map((index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Skeleton variant="rectangular" width={300} height={200} />
                    <Skeleton variant="text" width={300} height={30} />
                    <Skeleton variant="text" width={300} height={30} />
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            // Display actual data
            categoryproductData?.slice(0, displayedItems).map((item, index) => (
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3} key={item.id}>
                <Box>
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{
                      height: "250px",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </Box>

                <Box sx={{ textAlign: "justify" }}>
                  <br />
                  <Typography
                    variant="p"
                    sx={{
                      color: "var(--orange)",
                      fontSize: {
                        xs: "13px",
                        sm: "13px",
                        md: "15px",
                        lg: "18px",
                        xl: "18px",
                      },
                      textAlign: "justify",
                      fontWeight: "bold",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    sx={{
                      color: "var(light-black)",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "12px",
                        lg: "14px",
                        xl: "14px",
                      },
                      textAlign: "justify",
                      display: "-webkit-box",
                      WebkitLineClamp: maxLines,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.description?.split(".")[0]}.
                  </Typography>

                  <Box sx={{ marginTop: "10px" }}>
                    <NavLink
                      style={{
                        color: "var(--orange)",
                        textDecoration: "underline",
                      }}
                      to={`/products/${item.id}`}
                    >
                      Read More
                      <KeyboardDoubleArrowRightIcon />
                    </NavLink>
                  </Box>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      {displayedItems < categoryproductData?.length && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--orange)",
            border: "1px solid var(--orange)",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--orange)",
              color: "var(--white)",
              border: "1px solid var(--dark-skin)",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowMore}
        >
          Show More
          <KeyboardArrowDownIcon />
        </Button>
      )}
      {displayedItems > 8 && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--orange)",
            border: "1px solid var(--orange)",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--orange)",
              color: "var(--white)",
              border: "1px solid var(--dark-skin)",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowLess}
        >
          Show Less
          <KeyboardArrowUpIcon />
        </Button>
      )}
    </Container>
  );
};
