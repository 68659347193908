import React, { useState, useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "./FAQComp.css";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";
import { endpoint } from "../../constants/endpoint";

export const FAQComp = () => {
  const [expandedIds, setExpandedIds] = useState([]);

  const horizontalLine = {
    borderBottom: "1px solid var(--orange)",
    width: "100%",
  };

  const handleExpand = (id) => {
    setExpandedIds((prevIds) => [...prevIds, id]);
  };

  const handleNonExpand = (id) => {
    setExpandedIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
  };

  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { data: contactData } = useQuery({
    queryKey: "contactData",
    queryFn: getData,
  });

  const getFAQData = async () => {
    const res = await get(endpoint.faqs);
    return res.data;
  };

  const { isDataLoading, data: faqData } = useQuery({
    queryKey: ["faqData"],
    queryFn: getFAQData,
  });

  return (
    <Box sx={{ marginBottom: "230px", position: "relative", top: "128px" }}>
      <Box
        sx={{
          backgroundImage: `url(${contactData?.map((item) => {
            return item.faq_top_image;
          })})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: {
            xs: "contain",
            sm: "contain",
            md: "contain",
            lg: "cover",
            xl: "cover",
          }, // Use "contain" instead of "cover"
          backgroundPosition: "center",
          width: "100%",
          height: {
            xs: "20vh",
            sm: "40vh",
            md: "50vh",
            lg: "60vh",
            xl: "60vh",
          },
          marginBottom: {
            xs: "20px",
            sm: "40px",
            md: "60px",
            lg: "80px",
            xl: "80px",
          },
        }}
      ></Box>
      {isDataLoading ? (
        // Loading skeleton
        <Container sx={{ marginBottom: "10px", marginTop: "10px" }}>
          <Skeleton variant="text" width="100%" height="50px" />
          <Skeleton variant="text" width="100%" height="50px" />
        </Container>
      ) : (
        // Display actual data
        faqData?.map((item) => (
          <Container
            sx={{ marginBottom: "10px", marginTop: "10px" }}
            key={item.id}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "18px",
                    lg: "20px",
                    xl: "20px",
                  },
                  textAlign: "center",
                  fontWeight: "bold",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                # {item.question}
              </Typography>
              {!expandedIds.includes(item.id) ? (
                <Box
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    marginLeft: "auto",
                  }}
                >
                  <AddCircleIcon
                    fontSize="large"
                    onClick={() => handleExpand(item.id)}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    marginLeft: "auto",
                  }}
                >
                  <RemoveCircleOutlineIcon
                    fontSize="large"
                    onClick={() => handleNonExpand(item.id)}
                  />
                </Box>
              )}
            </Box>
            {expandedIds.includes(item.id) && (
              <Box
                sx={{
                  padding: "20px",
                  backgroundColor: "var(--light-grey)",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "16px",
                      xl: "16px",
                    },
                    textAlign: "center",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {item.answer}
                </Typography>
              </Box>
            )}

            <Box sx={horizontalLine}></Box>
          </Container>
        ))
      )}
    </Box>
  );
};
