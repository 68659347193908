import React from "react";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";
import { endpoint } from "../../constants/endpoint";

export const AboutTopSection = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.abouts);
    return res.data;
  };

  const { isDataLoading, data: aboutData } = useQuery({
    queryKey: ["aboutData"],
    queryFn: getData,
  });

  return (
    <Box sx={{ marginBottom: "200px", position: "relative", top: "128px" }}>
      {isDataLoading ? (
        <>
          <Skeleton variant="rectangular" width="100%" height="45vh" />
          <Container
            sx={{
              marginTop: "50px",
            }}
          >
            <Skeleton variant="text" width="100%" height="50px" />
            <Skeleton variant="text" width="100%" height="50px" />
            <Skeleton variant="text" width="100%" height="50px" />
          </Container>
        </>
      ) : (
        aboutData?.map((item) => {
          return (
            <Box key={item.id}>
              <Box>
                <img
                  src={item.image}
                  alt="..."
                  style={{
                    width: "100%",
                    height: "45vh",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </Box>
              <Container>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "justify",
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "15px",
                      lg: "18px",
                      xl: "18px",
                    },
                    margin: "30px 0px 50px 0px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                ></Typography>
              </Container>
            </Box>
          );
        })
      )}
    </Box>
  );
};
