import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";

export const Individualproduct = () => {
  const { id } = useParams();

  const [prodData, setProdData] = useState([]);

  // if (!filterData) {
  //   // Handle case when filterData is undefined
  //   return <p>Product not found</p>;
  // }

  useEffect(() => {
    // setTimeout(() => {
    const fetchLatestProductsDataAsync = async () => {
      try {
        const res = await axios.get(
          process.env.REACT_APP_KALUARTS_BASE_URL + `/products/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setProdData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLatestProductsDataAsync();
    // }, 1000);
  }, [id]);


  return (
    <Container
      sx={{ marginBottom: "200px", position: "relative", top: "125px" }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: "5%",
          marginBottom: "5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            sx={{
              "&:hover": {
                transform: "scale(1.1)",
                transition: "all 0.5s ease-in-out",
              },
            }}
          >
            <img
              src={prodData.image}
              alt={prodData.name}
              style={{
                height: "auto",
                width: "60%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={10} sm={10} md={5} lg={5} xl={5}>
          <Box
            sx={{
              textAlign: "justify",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="p"
              sx={{
                color: "var(--orange)",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "18px",
                  lg: "25px",
                  xl: "25px",
                },
                textAlign: "justify",
                fontWeight: "bold",
              }}
            >
              {prodData.name}
            </Typography>
            <br />
            <Typography
              variant="body1"
              sx={{
                color: "var(--light-black)",
                fontSize: {
                  xs: "13px",
                  sm: "13px",
                  md: "15px",
                  lg: "18px",
                  xl: "18px",
                },
                textAlign: "justify",
              }}
            >
              {prodData.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
