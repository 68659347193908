import React from "react";
import { Box } from "@mui/system";
import "./TopSection.css";
import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";
import { endpoint } from "../../../constants/endpoint";

export const TopSection = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isDataLoading, data: productsTopsectiondata } = useQuery({
    queryKey: "productsTopsectiondata",
    queryFn: getData,
  });

  return (
    <>
      {isDataLoading ? (
        <Skeleton variant="text" width="100%" height="45vh" />
      ) : (
        productsTopsectiondata?.map((item) => {
          return (
            <Box
              className="top-section"
              key={item.id}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                className="top-section-left"
                sx={{
                  width: {
                    xs: "95%",
                    sm: "95%",
                    md: "95%",
                    lg: "70%",
                    xl: "70%",
                  },
                  textAlign: "justify",
                  padding: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1rem",
                    lg: "3.2rem",
                    xl: "3.2rem",
                  },
                  zIndex: "1",
                }}
              >
                <Typography
                  variant="p"
                  className="top-section-left-heading"
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      md: "1.5rem",
                      lg: "2rem",
                      xl: "2.5rem",
                    },
                  }}
                >
                  {item.product_top_title}
                </Typography>

                <br />
                <Typography
                  variant="p"
                  className="top-section-left-subheading"
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "13px",
                      md: "15px",
                      lg: "18px",
                      xl: "18px",
                    },
                  }}
                >
                  {item.product_top_description}
                </Typography>
              </Box>
              <Box
                className="top-section-right"
                sx={{
                  zIndex: "0",
                  width: "380px",
                  height: "260px",
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <img
                  src={item.product_top_image}
                  alt="Dharma Good Top Section"
                  className="top-section-right-image"
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>
            </Box>
          );
        })
      )}
    </>
  );
};
