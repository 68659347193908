import React from "react";
import Slider from "react-slick";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { SampleNextArrow, SamplePrevArrow } from "../Shared/arrows/arrows";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { endpoint } from "../../constants/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";

export const Productcategory = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.categories);
    return res.data;
  };

  const { isDataLoading, data: categoriesData } = useQuery({
    queryKey: ["categoriesData"],
    queryFn: getData,
  });

  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box sx={{ marginBottom: "110px" }}>
      <Typography
        variant="h4"
        sx={{
          marginTop: "10px",
          fontWeight: "bold",
          fontSize: {
            xs: "20px",
            sm: "20px",
            md: "25px",
            lg: "30px",
            xl: "30px",
          },
        }}
      >
        PRODUCT CATEGORIES
      </Typography>
      <Slider {...settings}>
        {isDataLoading ? ( // Display skeleton while loading
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "20px",
              }}
            >
              {[1, 2, 3].map((index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Skeleton variant="rectangular" width={300} height={200} />
                  <Skeleton variant="text" width={300} height={30} />
                  <Skeleton variant="text" width={300} height={30} />
                </Box>
              ))}
            </Box>
          </>
        ) : (
          categoriesData?.map((item) => (
            <Box
              key={item.id}
              sx={{
                textAlign: "left",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginTop: "50px",
              }}
            >
              <Box
                sx={{
                  height: "380px",
                  width: "365px",
                  marginBottom: "20px",
                }}
              >
                <img
                  src={item.image}
                  alt={item.name}
                  style={{
                    height: "100%",
                    width: "100%",
                    marginBottom: "10px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </Box>

              <Typography
                variant="h4"
                sx={{
                  marginBottom: "10px",
                  display: "flex",
                  textAlign: "center",
                  color: "var(--orange)",
                  justifyContent: "flex-start",
                  fontWeight: "600",
                  fontSize: {
                    xs: "15px",
                    sm: "18px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                }}
                px
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  marginBottom: "10px",
                  display: "flex",
                  textAlign: "justify",
                  marginRight: "20px",
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "13px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              >
                {item.description}
              </Typography>
            </Box>
          ))
        )}
      </Slider>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "var(--orange)",
            color: "white",
            marginBottom: "10px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "15px",
              lg: "18px",
              xl: "18px",
            },
            ":hover": {
              transform: "scale(1.1)",
              backgroundColor: "var(--dark-skin)",
              color: "var(--white)",
            },
          }}
          onClick={() => navigate("/products")}
        >
          Show More
          <KeyboardDoubleArrowRightIcon className="arrow-icon" />
        </Button>
      </Box>
    </Box>
  );
};
