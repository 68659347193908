import React, { useState } from "react";
import ReactPlayer from "react-player";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import "./VideoSection.css"; // Import CSS file for custom styles
import Skeleton from "@mui/material/Skeleton";
import { endpoint } from "../../../constants/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";

export const VideoSection = () => {
  const [showPlayButton, setShowPlayButton] = useState(true);

  const handlePlay = () => {
    setShowPlayButton(false);
  };

  const handlePause = () => {
    setShowPlayButton(true);
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isDataLoading, data: contactData } = useQuery({
    queryKey: ["contactData"],
    queryFn: getData,
  });

  return (
    <Box sx={{ width: "100%" }}>
      {isDataLoading ? ( // Display skeleton while loading
        <Skeleton
          variant="rectangular"
          width="100%"
          height={500}
          sx={{
            marginBottom: "50px",
          }}
        />
      ) : (
        contactData?.map((item) => (
          <Box className="video-section-container" onClick={handlePlay}>
            {showPlayButton && (
              <IconButton
                aria-label="play"
                className="play-button"
                onClick={handlePlay}
              >
                <PlayCircleFilledIcon fontSize="large" />
              </IconButton>
            )}

            <ReactPlayer
              url={item.video}
              controls={true}
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                backgroundColor: "var(--light-black)",
                opacity: showPlayButton ? 0.7 : 1,
                transition: "opacity 0.5s ease-in-out",
                objectFit: "cover",
                objectPosition: "center",
              }}
              className="video-player"
              onPause={handlePause}
            />
          </Box>
        ))
      )}
    </Box>
  );
};
