import React from "react";
import { FAQComp } from "../../Components/FAQComponents/FAQComp";
import { Container } from "@mui/material";
import { Box } from "@mui/system";

export const FAQ = () => {
  return (
    <Box>
      <FAQComp />
    </Box>
  );
};
