import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";
import { endpoint } from "../../../constants/endpoint";

export const Category = () => {
  const navlinkStyle = {
    textDecoration: "none",
    color: "var(--white)",
    margin: "5px",
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.categories);
    return res.data;
  };

  const { data: categoriesData } = useQuery({
    queryKey: "categoriesData",
    queryFn: getData,
  });

  return (
    <>
      <Grid container xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "justify",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              variant="p"
              sx={{
                color: "var(--white)",
                fontWeight: "bold",

                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "17px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              Category
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
            }}
          >
            {categoriesData?.map((item) => {
              return (
                <NavLink to="/products" style={navlinkStyle}>
                  <Typography
                    variant="span"
                    sx={{
                      fontSize: {
                        xs: "9px",
                        sm: "13px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      "&:hover": {
                        color: "var(--orange)",
                      },
                    }}
                  >
                    {item.name}
                  </Typography>
                </NavLink>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
