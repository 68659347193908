import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

export const BottomNavbar = () => {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setSearchQuery("");
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery) {
      navigate(`/search-results?q=${searchQuery}`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/assets/logo.png"}
          alt="Responsiveimage"
          className="logo"
          style={{
            height: "6rem",
            width: "8rem",
            cursor: "pointer",
            margin: "1rem",
          }}
          onClick={() => navigate("/")}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: "8%",
            top: "35%",
          }}
        >
          {!isSearchOpen ? (
            <Button
              onClick={toggleSearch}
              sx={{
                backgroundColor: "transparent",
                color: "var(--light-black)",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <SearchIcon fontSize="large" />
            </Button>
          ) : (
            <form
              onSubmit={handleSearch}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <TextField
                id="search"
                type="search"
                label="Search ..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                inputProps={{
                  style: {
                    height: "16px",
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "18px",
                      lg: "20px",
                      xl: "20px",
                    },
                  },
                }}
                sx={{
                  width: {
                    xs: "180px",
                    sm: "180px",
                    md: "250px",
                    lg: "300px",
                    xl: "300px",
                  },
                  borderRadius: "5px",
                  backgroundColor: "var(--white)",
                  border: "0",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "var(--orange)",
                    },
                  },
                  // eslint-disable-next-line no-dupe-keys
                  "& .MuiOutlinedInput-root": {
                    border: "0px solid white",
                    borderRadius: "0",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid var(--orange)",
                  },
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid var(--orange)",
                    },
                  "& .MuiInputLabel-outlined.Mui-focused": {
                    color: "var(--orange)",
                  },
                }}
              />
              <Button
                type="submit"
                className="search-btn"
                variant="contained"
                sx={{
                  backgroundColor: "var(--orange)",
                  borderRadius: "2px",
                  padding: "0.43rem 1rem",
                  "&:hover": {
                    backgroundColor: "var(--orange)",
                  },
                }}
              >
                <SearchIcon fontSize="large" />
              </Button>

              <CloseIcon
                className="cross-icon"
                onClick={toggleSearch}
                fontSize="large"
                sx={{ margin: "0 0 0 1rem" }}
              />
            </form>
          )}
        </Box>
      </Box>
    </Box>
  );
};
