/* eslint-disable jsx-a11y/iframe-has-title */
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";
import { endpoint } from "../../constants/endpoint";

function MapBox() {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isDataLoading, data: contactData } = useQuery({
    queryKey: "contactData",
    queryFn: getData,
  });

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          padding: "10px 30px 10px 20px",
          textAlign: "center",
          marginTop: "30px",
          marginBottom: "80px",
        }}
      >
        {isDataLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          contactData?.map((item) => (
            <div key={item.id}>
              <Box
                sx={{
                  width: "60vmin",
                  height: "25vmin",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <iframe
                  style={{ width: "100%", height: "100%" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3534.252207321265!2d85.33282297519241!3d27.647667628121635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb17b11f6286a9%3A0xba24ef5c4010297e!2sKalu%20Arts%20%26%20Handicraft!5e0!3m2!1sen!2snp!4v1695540794149!5m2!1sen!2snp"
                ></iframe>
              </Box>
              <Box sx={{ margin: "5%" }}>
                {/* <Typography
                  variant="h6"
                  sx={{
                    margin: "5px",
                    color: "var(--orange)",
                    fontWeight: "600",
                  }}
                >
                  Contact Details
                </Typography> */}

                <Box
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    margin: "10px",
                  }}
                >
                  <LocationOnIcon fontSize="small" />{" "}
                  <Typography
                    variant="h9"
                    sx={{
                      marginLeft: "10px",
                      textAlign: "justify",
                      fontSize: {
                        xs: "0.8rem",
                        sm: "0.8rem",
                        md: "1rem",
                        lg: "1.2rem",
                        xl: "1.2rem",
                      },
                    }}
                  >
                    {item.location}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    margin: "10px",
                  }}
                >
                  <PhoneIcon fontSize="small" />{" "}
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontSize: {
                        xs: "0.8rem",
                        sm: "0.8rem",
                        md: "1rem",
                        lg: "1.2rem",
                        xl: "1.2rem",
                      },
                    }}
                  >
                    {item.phone}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    margin: "10px",
                  }}
                >
                  <EmailIcon fontSize="small" />{" "}
                  <Typography
                    sx={{
                      marginLeft: "10px",
                      fontSize: {
                        xs: "0.8rem",
                        sm: "0.8rem",
                        md: "1rem",
                        lg: "1.2rem",
                        xl: "1.2rem",
                      },
                    }}
                  >
                    {item.email}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "10px",
                }}
              >
                <a href={item.facebook} target="_blank" rel="noreferrer">
                  <FacebookIcon fontSize="Large" />
                </a>
              </Box>
            </div>
          ))
        )}
      </Paper>
    </>
  );
}

export default MapBox;
