import React from "react";
import { ContactForm } from "../../Components/ContactComponents/ContactForm";
import MapBox from "../../Components/ContactComponents/MapBox";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";
import { endpoint } from "../../constants/endpoint";

export const Contact = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { data: contactData } = useQuery({
    queryKey: "contactData",
    queryFn: getData,
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10%",
          flexWrap: "wrap",
          justifyContent: "center",
          marginBottom: "200px",
          // position: "relative",
          // top: "185px",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${contactData?.map((item) => {
              return item.contact_top_image;
            })})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: {
              xs: "contain",
              sm: "contain",
              md: "contain",
              lg: "cover",
              xl: "cover",
            }, // Use "contain" instead of "cover"
            backgroundPosition: "center",
            width: "100%",
            height: {
              xs: "50vh",
              sm: "50vh",
              md: "50vh",
              lg: "60vh",
              xl: "60vh",
            },
            marginBottom: {
              xs: "20px",
              sm: "40px",
              md: "60px",
              lg: "80px",
              xl: "80px",
            },
          }}
        ></Box>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ContactForm />
        </Box>
        <Box
          sx={{
            width: "50vmin",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MapBox />
        </Box>
      </Box>
    </>
  );
};
