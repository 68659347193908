import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { Grid } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";
import { endpoint } from "../../constants/endpoint";

export const LatestproductSection = () => {
  const navigate = useNavigate();
  const maxLines = 2; // Maximum number of lines in the remaining description

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.latest_product);
    return res.data;
  };

  const { isDataLoading, data: latestProductsData } = useQuery({
    queryKey: ["latestProductsData"],
    queryFn: getData,
  });

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
          marginBottom: "20px",
          fontSize: {
            xs: "20px",
            sm: "20px",
            md: "25px",
            lg: "30px",
            xl: "30px",
          },
        }}
      >
        OUR LATEST COLLECTION
      </Typography>
      <br />
      <Box sx={{ marginBottom: "60px" }}>
        <Grid container spacing={3} sx={{ marginBottom: "40px" }}>
          {isDataLoading ? ( // Display skeleton while loading
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Skeleton variant="rectangular" width={300} height={200} />
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="rectangular" width={300} height={200} />
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="rectangular" width={300} height={200} />
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="rectangular" width={300} height={200} />
                <Skeleton variant="text" width={300} height={30} />
                <Skeleton variant="text" width={300} height={30} />
              </Box>
            </Box>
          ) : (
            latestProductsData?.map((item) => (
              <Grid item xs={6} sm={6} md={4} lg={3} xl={3} key={item.id}>
                <Box>
                  <img
                    src={item.image}
                    alt={item.name}
                    style={{
                      height: "250px",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                </Box>

                <Box sx={{ textAlign: "justify" }}>
                  <br />
                  <Typography
                    variant="p"
                    sx={{
                      color: "var(--orange)",
                      fontSize: {
                        xs: "13px",
                        sm: "13px",
                        md: "15px",
                        lg: "18px",
                        xl: "18px",
                      },
                      textAlign: "justify",
                      fontWeight: "bold",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    sx={{
                      color: "var(--light-black)",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "12px",
                        lg: "14px",
                        xl: "14px",
                      },
                      textAlign: "justify",
                      display: "-webkit-box",
                      WebkitLineClamp: maxLines,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.description?.split(".")[0]}.
                  </Typography>

                  <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
                    <NavLink
                      style={{
                        color: "var(--orange)",
                        textDecoration: "underline",
                      }}
                      to={`/products/${item.id}`}
                    >
                      Read More
                      <KeyboardDoubleArrowRightIcon />
                    </NavLink>
                  </Box>
                </Box>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "var(--orange)",
          fontSize: {
            xs: "11px",
            sm: "11px",
            md: "15px",
            lg: "18px",
            xl: "18px",
          },
          marginTop: "30px",
          marginBottom: "80px",
          "&:hover": {
            backgroundColor: "var(--dark-skin)",
            color: "white",
          },
        }}
        onClick={() => navigate("/products")}
      >
        Show More
        <KeyboardDoubleArrowRightIcon className="arrow-icon" />
      </Button>
    </Box>
  );
};
