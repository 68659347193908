import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { Grid } from "@mui/material";
import "./CSRComp.css";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";
import { endpoint } from "../../constants/endpoint";

export const CSRComp = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.csrs);
    return res.data;
  };

  const { isDataLoading, data: csrData } = useQuery({
    queryKey: ["csrData"],
    queryFn: getData,
  });

  return (
    <Container
      sx={{ marginBottom: "200px", position: "relative", top: "150px" }}
    >
      {isDataLoading ? (
        // Loading skeleton
        <Box
          sx={{
            marginBottom: "40px",
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height="300px" />
          <Skeleton variant="text" width="100%" height="50px" />
        </Box>
      ) : (
        // Display actual data
        csrData?.map((item, index) => {
          const isImageLeft = index % 2 === 0; // Check if the index is even
          return (
            <Box key={item.id}>
              <Grid
                container
                xs={12}
                md={12}
                lg={12}
                xl={12}
                spacing={4}
                sx={{
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                key={item.id}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    order: {
                      xs: 0,
                      sm: 0,
                      md: isImageLeft ? 1 : 2,
                      lg: isImageLeft ? 1 : 2,
                      xl: isImageLeft ? 1 : 2,
                    },
                  }}
                >
                  <Box className="csr-img">
                    <img
                      src={item.image}
                      alt=""
                      style={{
                        width: "90%",
                        height: "auto",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  xl={6}
                  sx={{
                    textAlign: "justify ",
                    order: {
                      xs: 0,
                      sm: 0,
                      md: isImageLeft ? 2 : 1,
                      lg: isImageLeft ? 2 : 1,
                      xl: isImageLeft ? 2 : 1,
                    },
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: {
                        xs: "15px",
                        sm: "18px",
                        md: "20px",
                        lg: "25px",
                        xl: "25px",
                      },
                      fontWeight: "bold",
                      color: "var(--light-black)",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <br />
                  <br />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                      color: "var(--light-black)",
                    }}
                  >
                    {item.description}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })
      )}
    </Container>
  );
};
