import React from "react";
import { Box } from "@mui/system";
import { TopSection } from "../../Components/ProductComponents/TopSection/TopSection";
import { Searchpage } from "../../Components/Searchpage/Searchpage";

export const SearchPage = () => {
  return (
    <Box sx={{ marginBottom: "200px", position: "relative", top: "128px" }}>
      <Box>
        <TopSection />
      </Box>
      <Box>
        <Searchpage />
      </Box>
    </Box>
  );
};
