import React from "react";
import TopSlider from "../../Components/HomeComponents/TopSlider/TopSlider";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { WelcomeSection } from "../../Components/HomeComponents/WelcomeSection";
import { LatestproductSection } from "../../Components/HomeComponents/LatestproductSection";
import { VideoSection } from "../../Components/HomeComponents/VideoSection/VideoSection";
import { Productcategory } from "../../Components/HomeComponents/Productcategory";
import CustomerReviews from "../../Components/HomeComponents/CustomerReviews/CustomerReviews";

export const Home = () => {
  return (
    <Box>
      <TopSlider />
      <Container>
        <WelcomeSection />
        <LatestproductSection />
        <VideoSection />
        <Productcategory />
        <CustomerReviews />
      </Container>
    </Box>
  );
};
