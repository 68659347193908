import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";
import { endpoint } from "../../../constants/endpoint";

export const Contact = () => {
  const navlinkStyle = {
    textDecoration: "none",
    color: "var(--white)",
    margin: "5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const {  data: contactData } = useQuery({
    queryKey: "contactData",
    queryFn: getData,
  });

  return (
    <Box>
      {contactData?.map((item) => {
        return (
          <Grid container xs={12}>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "justify",
              }}
            >
              <Box sx={{ marginBottom: "20px" }}>
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--white)",
                    fontWeight: "bold",

                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "17px",
                      lg: "20px",
                      xl: "20px",
                    },
                  }}
                >
                  Contact Info
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "13px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              >
                <NavLink to="/contactus" style={navlinkStyle}>
                  <LocationOnIcon
                    fontSize="small"
                    sx={{
                      "&:hover": {
                        color: "var(--orange)",
                        transition: "all 0.5s ease-in-out",
                      },
                    }}
                  />
                  <Typography
                    variant="span"
                    sx={{
                      marginLeft: "10px",
                      fontSize: {
                        xs: "9px",
                        sm: "13px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      "&:hover": {
                        color: "var(--orange)",
                      },
                    }}
                  >
                    {item.location}
                  </Typography>
                </NavLink>
                <NavLink to="/contactus" style={navlinkStyle}>
                  <LocalPhoneIcon
                    fontSize="small"
                    sx={{
                      "&:hover": {
                        color: "var(--orange)",
                        transition: "all 0.5s ease-in-out",
                      },
                    }}
                  />
                  <Typography
                    variant="span"
                    sx={{
                      marginLeft: "10px",
                      fontSize: {
                        xs: "9px",
                        sm: "13px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      "&:hover": {
                        color: "var(--orange)",
                      },
                    }}
                  >
                    {item.phone}
                  </Typography>
                </NavLink>
                <NavLink to="/contactus" style={navlinkStyle}>
                  <EmailIcon
                    fontSize="small"
                    sx={{
                      "&:hover": {
                        color: "var(--orange)",
                        transition: "all 0.5s ease-in-out",
                      },
                    }}
                  />
                  <Typography
                    variant="span"
                    sx={{
                      marginLeft: {
                        xs: "10px",
                        sm: "10px",
                        md: "10px",
                        lg: "10px",
                        xl: "10px",
                      },
                      fontSize: {
                        xs: "9px",
                        sm: "13px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      "&:hover": {
                        color: "var(--orange)",
                      },
                    }}
                  >
                    {item.email}
                  </Typography>
                </NavLink>
              </Box>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};
