import React from "react";
import { CSRComp } from "../../Components/CSRComponents/CSRComp";
import { TopSectionCSR } from "../../Components/CSRComponents/CSRTopSection/CSRTopSection";

export const CSR = () => {
  return (
    <div>
      <TopSectionCSR />
      <CSRComp />
    </div>
  );
};
