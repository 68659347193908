import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./ContactForm.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ContactForm = () => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Required")
        .min(2, "Must be 2 characters or more"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Required")
        .matches(
          /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
          "Invalid email address"
        ),
      subject: Yup.string()
        .required("Required")
        .min(2, "Must be 2 characters or more")
        .max(20, "Must be 50 characters or less"),
      message: Yup.string().max(100, "Must be 100 characters or less"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await axios.post(
          process.env.REACT_APP_KALUARTS_BASE_URL + `/contacts`,
          values,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        toast.success("Email sent successfully");
        formik.resetForm();
      } catch (error) {
        toast.error("Email not sent");
      }
      setLoading(false);
    },
  });

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "white",
        padding: "2rem",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <TextField
              autoComplete="fname"
              name="name"
              variant="outlined"
              type="text"
              required
              id="name"
              label="Name"
              autoFocus
              disabled={loading}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              sx={{
                width: "100%",
                color: "#99C386",
                backgroundColor: "var(--light-grey)",
                " & .MuiOutlinedInput-root": {
                  "  &.Mui-focused fieldset": {
                    borderColor: "var(--orange)",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  border: "0px solid white",
                  borderRadius: "0",
                  padding: "0",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid var(--orange)",
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--orange)",
                  },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "var(--orange)",
                },
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              required
              id="email"
              label="Email Address"
              name="email"
              type="email"
              autoComplete="email"
              disabled={loading}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              sx={{
                width: "100%",
                color: "#99C386",
                backgroundColor: "var(--light-grey)",
                " & .MuiOutlinedInput-root": {
                  "  &.Mui-focused fieldset": {
                    borderColor: "var(--orange)",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  border: "0px solid white",
                  borderRadius: "0",
                  padding: "0",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid var(--orange)",
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--orange)",
                  },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "var(--orange)",
                },
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              required
              id="subject"
              label="Subject"
              name="subject"
              type="text"
              autoComplete="text"
              disabled={loading}
              value={formik.values.subject}
              onChange={formik.handleChange}
              error={formik.touched.subject && formik.errors.subject}
              helperText={formik.touched.subject && formik.errors.subject}
              sx={{
                width: "100%",
                color: "#99C386",
                backgroundColor: "var(--light-grey)",
                " & .MuiOutlinedInput-root": {
                  "  &.Mui-focused fieldset": {
                    borderColor: "var(--orange)",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  border: "0px solid white",
                  borderRadius: "0",
                  padding: "0",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid var(--orange)",
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--orange)",
                  },
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "var(--orange)",
                },
              }}
            />
          </Grid>
          <Grid item>
            <TextareaAutosize
              aria-label="minimum height"
              type="text"
              minRows={3}
              placeholder="Message"
              style={{
                height: 100,
                backgroundColor: "var(--light-grey)",
                border: "1px solid var(--orange)",
                outlineColor: "var(--orange)",
              }}
              className="form-message"
              name="message"
              disabled={loading}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && formik.errors.message}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{
                backgroundColor: "var(--orange)",
                width: "150px",
                "&:hover": {
                  backgroundColor: "var(--dark-skin)",
                  color: "var(--white)",
                },
              }}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send"}
              <KeyboardDoubleArrowRightIcon className="arrow-icon" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
