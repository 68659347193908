import React from "react";
import { AboutTopSection } from "../../Components/AboutComponents/AboutTopSection";

export const About = () => {
  return (
    <div>
      <AboutTopSection />
    </div>
  );
};
