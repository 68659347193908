import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import Carousel from "react-bootstrap/Carousel";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";
import { endpoint } from "../../../constants/endpoint";

export default function CustomerReviews() {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.testimonials);
    return res.data;
  };

  const { isDataLoading, data: testimonialData } = useQuery({
    queryKey: ["testimonialData"],
    queryFn: getData,
  });

  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "600",
          fontSize: {
            xs: "20px",
            sm: "20px",
            md: "25px",
            lg: "30px",
            xl: "30px",
          },
        }}
      >
        VOICE FROM OUR CUSTOMERS
      </Typography>
      <MDBContainer fluid className="py-3 gradient-custom">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol md="12">
            <div className="text-center mb-4 pb-2">
              <MDBIcon fas icon="quote-left" size="3x" className="text-white" />
            </div>
            <MDBCard>
              <MDBCardBody className="px-4 py-5">
                <Carousel
                  fade
                  prevIcon={
                    <KeyboardDoubleArrowLeftIcon
                      fontSize="large"
                      sx={{ color: "var(--light-black)" }}
                    />
                  }
                  nextIcon={
                    <KeyboardDoubleArrowRightIcon
                      fontSize="large"
                      sx={{ color: "var(--light-black)" }}
                    />
                  }
                >
                  {isDataLoading ? ( // Display skeleton while loading
                    <Carousel.Item key="loading">
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={200}
                      />
                    </Carousel.Item>
                  ) : (
                    testimonialData?.map((item, index) => (
                      <Carousel.Item key={index}>
                        <Container
                          sx={{
                            display: "flex",
                            flexDirection: {
                              xs: "column",
                              sm: "column",
                              md: "row",
                              lg: "row",
                              xl: "row",
                            },
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: {
                                xs: "40vw",
                                sm: "15vw",
                                md: "15vw",
                                lg: "10vw",
                                xl: "10vw",
                              },
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={item.image}
                              alt="Responsiveimage"
                              style={{
                                height: "150px",
                                width: "100%",
                                borderRadius: "50%",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: {
                                xs: "100%",
                                sm: "70%",
                                md: "70%",
                                lg: "50%",
                                xl: "50%",
                              },
                              textAlign: "justify",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                color: "var(--light-black)",
                                fontSize: {
                                  xs: "14px",
                                  sm: "14px",
                                  md: "12px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                                margin: "10px",
                                display: "flex",
                                fontWeight: "600",
                                justifyContent: "flex-start",
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              variant="p"
                              sx={{
                                color: "var(--light-black)",
                                fontSize: {
                                  xs: "11px",
                                  sm: "11px",
                                  md: "12px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                                margin: "10px",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              "{item.description}"
                            </Typography>
                          </Box>
                        </Container>
                      </Carousel.Item>
                    ))
                  )}
                </Carousel>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Box>
  );
}
