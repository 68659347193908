import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export const NavigationLinks = () => {
  const navlinkStyle = {
    textDecoration: "none",
    color: "var(--white)",
    margin: "5px",
  };

  return (
    <>
      <Grid container xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "justify",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              variant="p"
              sx={{
                color: "var(--white)",
                fontWeight: "bold",

                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "17px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              Navigation Links
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
            }}
          >
            <NavLink to="/" style={navlinkStyle}>
              <Typography
                variant="span"
                sx={{
                  fontSize: {
                    xs: "9px",
                    sm: "13px",
                    md: "15px",
                    lg: "16px",
                    xl: "16px",
                  },
                  "&:hover": {
                    color: "var(--orange)",
                  },
                }}
              >
                Home
              </Typography>
            </NavLink>
            <NavLink to="/about" style={navlinkStyle}>
              <Typography
                variant="span"
                sx={{
                  fontSize: {
                    xs: "9px",
                    sm: "13px",
                    md: "15px",
                    lg: "16px",
                    xl: "16px",
                  },
                  "&:hover": {
                    color: "var(--orange)",
                  },
                }}
              >
                About Us
              </Typography>
            </NavLink>
            <NavLink to="/products" style={navlinkStyle}>
              <Typography
                variant="span"
                sx={{
                  fontSize: {
                    xs: "9px",
                    sm: "13px",
                    md: "15px",
                    lg: "16px",
                    xl: "16px",
                  },
                  "&:hover": {
                    color: "var(--orange)",
                  },
                }}
              >
                Products
              </Typography>
            </NavLink>
            <NavLink to="/csr" style={navlinkStyle}>
              <Typography
                variant="span"
                sx={{
                  fontSize: {
                    xs: "9px",
                    sm: "13px",
                    md: "15px",
                    lg: "16px",
                    xl: "16px",
                  },
                  "&:hover": {
                    color: "var(--orange)",
                  },
                }}
              >
                CSR
              </Typography>
            </NavLink>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
