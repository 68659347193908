import React from "react";

export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        height: "0px",
        width: "0px",
        zIndex: "1",
        right: "100px",
        color: "#cc9669",
      }}
      onClick={onClick}
      alt="Next"
    />
  );
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        height: "0px",
        width: "0px",
        zIndex: "1",
        left: "100px",
      }}
      onClick={onClick}
      alt="Previous"
    />
  );
}

////for container components
export function ContNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/next.png"}
      style={{
        ...style,
        display: "block",
        height: "90px",
        width: "90px",
        zIndex: "1",
        right: "-28px",
        top: "150px",
      }}
      onClick={onClick}
      alt="Next"
    />
  );
}

export function ContPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/pervious.png"}
      style={{
        ...style,
        display: "block",
        height: "90px",
        width: "90px",
        zIndex: "1",
        left: "-45px",
        top: "150px",
      }}
      onClick={onClick}
      alt="Previous"
    />
  );
}
