import React from "react";
import { NavBar } from "./NavBar/NavBar";
import { BottomNavbar } from "./BottomNavbar";

export const Appbar = () => {
  return (
    <div
      style={{
        backgroundImage: "url(./assets/header-bg.png)",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000, // Increase the z-index to make sure it appears on top
        backgroundColor: "var(--white)",
        width: "100%",
      }}
    >
      <NavBar />
      <BottomNavbar />
    </div>
  );
};
