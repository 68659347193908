import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./HomeComponent.css";
import { useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { endpoint } from "../../constants/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";

export const WelcomeSection = () => {
  const navigate = useNavigate();

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.abouts);
    return res.data;
  };

  const { isDataLoading, data: aboutData } = useQuery({
    queryKey: ["aboutData"],
    queryFn: getData,
  });

  return (
    <Box sx={{ marginBottom: "120px" }}>
      {isDataLoading ? ( // Display skeleton while loading
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem" }}
            width={400}
            height={50}
          />
          <Skeleton variant="rectangular" width={"100%"} height={100} />
        </Box>
      ) : (
        aboutData?.map((item) => {
          return (
            <Box sx={{ marginBottom: "50px" }} key={item.id}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "600",
                  fontSize: {
                    xs: "20px",
                    sm: "20px",
                    md: "25px",
                    lg: "30px",
                    xl: "30px",
                  },
                }}
              >
                {item.title}
              </Typography>
              <br />
              <Typography
                variant="body1"
                sx={{
                  textAlign: "justify",
                  marginBottom: "20px",
                  fontSize: {
                    xs: "14px",
                    sm: "14px",
                    md: "18px",
                    lg: "20px",
                    xl: "20px",
                  },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 4,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.description}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "var(--orange)",
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "15px",
                    lg: "18px",
                    xl: "18px",
                  },
                  "&:hover": {
                    backgroundColor: "var(--dark-skin)",
                    color: "var(--white)",
                  },
                }}
                onClick={() => navigate("/about")}
              >
                Read More
                <KeyboardDoubleArrowRightIcon className="arrow-icon" />
              </Button>
            </Box>
          );
        })
      )}
    </Box>
  );
};
