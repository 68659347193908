export const endpoint = {
  sliders: `sliders`,
  abouts: `abouts`,
  latest_product: `latest-product`,
  sitesettings: `sitesettings`,
  categories: `categories`,
  testimonials: `testimonials`,
  products: `products`,
  category_product: `category-product`,
  csrs: `csrs`,
  faqs: `faqs`,
  newsletters: `newsletters`,
  contacts: `contacts`,
  search: `search`,
};
