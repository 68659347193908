import React from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { TopSection } from "../../Components/ProductComponents/TopSection/TopSection";
import { useState } from "react";
import { AllProducts } from "../../Components/ProductComponents/AllProducts/AllProducts";
import { Categoryproducts } from "../../Components/ProductComponents/Categoryproducts/Categoryproducts";
import { useNavigate } from "react-router-dom";
import { endpoint } from "../../constants/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useAPI";

export const Product = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("all");
  const handleOptionClick = (option) => {
    setSelected(option);
    navigate(`/products#${option}`);
  };
  const btn = {
    margin: "0.3rem",
    backgroundColor: "white",
    color: "#cc9669",
    height: "30px",
    padding: "10px",
    borderRadius: "10px",
    border: "1px solid #cc9669",
    boxShadow: "0px 0px 0px 0px",

    //HOVER
    "&:hover": {
      backgroundColor: "#ad774b",
      color: "white",
      border: "0.5px solid #96195a",
    },
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.categories);
    return res.data;
  };

  const { isDataLoading, data: categoriesData } = useQuery({
    queryKey: "categoriesData",
    queryFn: getData,
  });
  const renderSelectedComponent = () => {
    // eslint-disable-next-line default-case

    switch (selected) {
      case "all":
        return <AllProducts />;
      default:
        // Find the selected category in the dharmagoodcategories array
        const selectedCategory = categoriesData?.find(
          (item) => item.name === selected
        );

        if (selectedCategory) {
          return <Categoryproducts id={selectedCategory.id} />;
        } else {
          return <AllProducts />;
        }
    }
  };

  return (
    <Box sx={{ marginBottom: "200px", position: "relative", top: "128px" }}>
      <Box>
        <TopSection />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" },
          margin: "auto",
          marginBottom: "50px",
        }}
      >
        <Button
          variant="contained"
          sx={
            selected === "all"
              ? { ...btn, backgroundColor: "var(--orange)", color: "white" }
              : btn
          }
          to={`#${selected}`}
          onClick={() => handleOptionClick("all")}
        >
          ALL
        </Button>
        {categoriesData?.map((item) => {
          return (
            <Button
              variant="contained"
              sx={
                selected === item.name
                  ? { ...btn, backgroundColor: "var(--orange)", color: "white" }
                  : btn
              }
              to={`#${item.name}`}
              onClick={() => handleOptionClick(item.name)}
            >
              {item.name}
            </Button>
          );
        })}
      </Box>
      {renderSelectedComponent()}
    </Box>
  );
};
