import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@mui/system";
import "./NavBar.css";

export const NavBar = () => {
  const [activeNavItem, setActiveNavItem] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
  };

  useEffect(() => {
    const closeNavbar = () => {
      setIsOpen(false);
    };

    const navLinks = document.querySelectorAll(".nav-link");
    navLinks.forEach((link) => {
      link.addEventListener("click", closeNavbar);
    });

    return () => {
      navLinks.forEach((link) => {
        link.removeEventListener("click", closeNavbar);
      });
    };
  }, []);

  return (
    <>
      <div
        id="nav-icon1"
        className={`nav-icon ${isOpen ? "open" : ""}`}
        onClick={handleClick}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <Box className={`sidebar ${isOpen ? "open" : ""}`}>
        <Box className="content">
          <NavLink
            to="/"
            className={`nav-link ${activeNavItem === "home" ? "active" : ""}`}
            onClick={() => handleNavItemClick("home")}
          >
            HOME
          </NavLink>
          <NavLink
            to="/about"
            className={`nav-link ${activeNavItem === "about" ? "active" : ""}`}
            onClick={() => handleNavItemClick("about")}
          >
            ABOUT
          </NavLink>
          <NavLink
            to="/products"
            className={`nav-link ${
              activeNavItem === "products" ? "active" : ""
            }`}
            onClick={() => handleNavItemClick("products")}
          >
            PRODUCTS
          </NavLink>
          <NavLink
            to="/csr"
            className={`nav-link ${activeNavItem === "csr" ? "active" : ""}`}
            onClick={() => handleNavItemClick("csr")}
          >
            CSR
          </NavLink>
          <NavLink
            to="/faq"
            className={`nav-link ${activeNavItem === "faq" ? "active" : ""}`}
            onClick={() => handleNavItemClick("faq")}
          >
            FAQ
          </NavLink>
          <NavLink
            to="/contactus"
            className={`nav-link ${
              activeNavItem === "contactus" ? "active" : ""
            }`}
            onClick={() => handleNavItemClick("contactus")}
          >
            CONTACT US
          </NavLink>
        </Box>
      </Box>
    </>
  );
};
