import React from "react";
import { Container, Typography } from "@mui/material";
import { Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./TopSlider.css";
import Skeleton from "@mui/material/Skeleton";
import { endpoint } from "../../../constants/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";

export default function TopSlider() {
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <KeyboardDoubleArrowRightIcon
        fontSize="large"
        sx={{
          color: "var(--light-black)",
          display: "block",
          right: "60px",
          zIndex: 1,
        }}
      />
    ),
    prevArrow: (
      <KeyboardDoubleArrowLeftIcon
        fontSize="large"
        sx={{
          color: "var(--light-black)",
          display: "block",
          left: "60px",
          zIndex: 1,
        }}
      />
    ),
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sliders);
    return res.data;
  };

  const { isDataLoading, data: TopSliderData } = useQuery({
    queryKey: ["topSliderData"],
    queryFn: getData,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "200px",
        position: "relative",
        top: "125px",
        height: "100%",
      }}
    >
      <Slider {...settings} style={{ width: "100%" }}>
        {isDataLoading ? ( // Display skeleton while loading
          <Skeleton variant="rectangular" width={"100%"} height={"60vh"} />
        ) : (
          TopSliderData?.map((item, index) => (
            <Box
              key={index}
              className="banner-container"
              sx={{
                padding: {
                  xs: "30px",
                  sm: "60px",
                  md: "70px",
                  lg: "80px",
                  xl: "80px",
                },
              }}
            >
              <Container>
                <Grid
                  container
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    },
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "50%",
                          lg: "90%",
                          xl: "90%",
                        },
                        height: {
                          xs: "100%",
                          sm: "50%",
                          ms: "50%",
                          lg: "90%",
                          xl: "90%",
                        },
                        marginBottom: {
                          xs: "1rem",
                          sm: "1rem",
                          md: "0",
                          lg: "0",
                          xl: "0",
                        },
                        transform: {
                          xs: "translateY(0%)",
                          sm: "translateY(0%)",
                          md: "perspective(500px) rotateY(12deg)",
                          lg: "perspective(500px) rotateY(12deg)",
                          xl: "perspective(500px) rotateY(12deg)",
                        },
                        position: "relative",
                        zIndex: 2,
                      }}
                      className="banner-img-container"
                    >
                      <img
                        src={item.image}
                        alt="Responsiveimage"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                        className="banner-img"
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={7}
                    xl={7}
                    sx={{ textAlign: "justify" }}
                  >
                    <Box
                      className="banner-details"
                      sx={{
                        marginLeft: {
                          xs: "0",
                          sm: "0",
                          md: "-180px",
                          lg: "-100px",
                          xl: "-100px",
                        },
                        backgroundColor: "var(--light-skin)",
                        padding: {
                          xs: "1rem",
                          sm: "1rem",
                          md: "40px 30px 43px 80px",
                          lg: "40px 30px 43px 80px",
                          xl: "40px 30px 43px 80px",
                        },
                        position: "relative",
                        zIndex: 1,
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "calc(100% + 185px)",
                          lg: "calc(100% + 125px)",
                          xl: "calc(100% + 125px)",
                        },
                        flex: 1,
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: {
                            xs: "15px",
                            sm: "15px",
                            md: "25px",
                            lg: "30px",
                            xl: "30px",
                          },
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <br />
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "15px",
                            lg: "20px",
                            xl: "20px",
                          },
                          textAlign: "center",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          ))
        )}
      </Slider>
    </Box>
  );
}
