import React from "react";
import { Box } from "@mui/system";
import "./CSRTopSection.css";
import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useAPI";
import { endpoint } from "../../../constants/endpoint";

export const TopSectionCSR = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isDataLoading, data: CSRTopSectionData } = useQuery({
    queryKey: ["CSRTopSectionData"],
    queryFn: getData,
  });

  return (
    <>
      {isDataLoading ? (
        // Loading skeleton
        <Skeleton variant="text" width="100%" height="90vh" />
      ) : (
        // Display actual data
        CSRTopSectionData?.map((item) => (
          <Box
            className="top-section"
            key={item.id}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
              marginBottom: "100px",
              position: "relative",
              top: "128px",
            }}
          >
            <Box
              className="top-section-left"
              sx={{
                width: {
                  xs: "95%",
                  sm: "95%",
                  md: "95%",
                  lg: "70%",
                  xl: "70%",
                },
                textAlign: "justify",
                padding: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "3.2rem",
                  xl: "3.2rem",
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                zIndex: "1",
              }}
            >
              <Typography
                variant="p"
                className="top-section-left-heading"
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1.5rem",
                    lg: "2rem",
                    xl: "2.5rem",
                  },
                }}
              >
                {item.csr_top_title}
              </Typography>

              <br />
              <Typography
                variant="p"
                className="top-section-left-subheading"
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "15px",
                    lg: "18px",
                    xl: "18px",
                  },
                }}
              >
                {item.csr_top_description}
              </Typography>
            </Box>
            <Box
              className="top-section-right"
              sx={{
                zIndex: "0",
                width: "30%",
                height: "20%",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <img
                src={item.csr_top_image}
                alt="Dharma Good Top Section"
                className="top-section-right-image"
                style={{ height: "auto", width: "100%" }}
              />
            </Box>
          </Box>
        ))
      )}
    </>
  );
};
